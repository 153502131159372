export const partOrder = [
  'Sop',
  'Sop1',
  'Sop2',
  'Alto',
  'Alto1',
  'Alto2',
  'Tenor',
  'Lead',
  'Baritone',
  'Bari',
  'Bass',
  'Piano',
];
