import React from "react";
import PT from "prop-types";
import MemberUpdate from "./MemberUpdate";

class MemberUpdateList extends React.Component {
  state = {
    activeUpdateId: null,
  };

  handleClickUpdate = (updateId) => {
    this.setState(({ activeUpdateId }) => {
      return { activeUpdateId: updateId === activeUpdateId ? null : updateId };
    });
  };

  render() {
    const { memberUpdates } = this.props;
    return memberUpdates.map(({ node }) => (
      <MemberUpdate
        memberUpdate={node}
        key={node.id}
        isActive={node.id === this.state.activeUpdateId}
        handleClickUpdate={this.handleClickUpdate}
      />
    ));
  }
}

MemberUpdateList.propTypes = {
  memberUpdates: PT.arrayOf(PT.shape).isRequired,
};

export default MemberUpdateList;
