import React from 'react';
import { graphql } from 'gatsby';
import PT from 'prop-types';
import AuthContext from '../../contexts/AuthContext';
import ResourceSearch from '../../components/ResourceSearch';
import TemplateWrapper from '../../layouts';

const Resource = ({ data }) => {
  const { resourcesMd, memberUpdatesMd } = data;
  function generateResourceElement(codeInputted) {
    return codeInputted ? (
      <ResourceSearch
        resources={resourcesMd.edges}
        memberUpdates={memberUpdatesMd.edges}
        resourceTypes={[
          'member updates',
          'choir recordings',
          'warmups',
          'education',
          'miscellaneous',
          'links',
        ]}
      />
    ) : (
      <p>Please log in :)</p>
    );
  }
  return (
    <TemplateWrapper>
      <AuthContext.Consumer>
        {({ codeInputted }) => generateResourceElement(codeInputted)}
      </AuthContext.Consumer>
    </TemplateWrapper>
  );
};

Resource.propTypes = {
  data: PT.shape({
    allMarkdownRemark: PT.object,
  }).isRequired,
};

export default Resource;

export const pageQuery = graphql`
  query ResourceQuery {
    site {
      siteMetadata {
        title
      }
    }
    resourcesMd: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "resources" } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            title
            type
            group
            parts
            sourceLink
            media
            extra
          }
        }
      }
    }
    memberUpdatesMd: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "update" }, type: { eq: "members" } }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            templateKey
            title
            type
            date
          }
        }
      }
    }
  }
`;
