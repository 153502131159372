import React from 'react';
import {
  ResourceWrapper,
  ResourceLinkArea,
  Navbar,
  FlexCollection,
} from './styled-components/structures';
import {
  ResourceTitle,
  LineFade,
  ResourceLink,
  FlexButton,
  ButtonText,
  Opening,
} from './styled-components/elements';
import { partOrder } from '../utils/ref';
import MemberUpdateList from './MemberUpdateList';

class ResourceSearch extends React.Component {
  state = {
    activeType: this.props.resourceTypes[0],
    activeResource: null,
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.resourceTypes !== prevProps.resourceTypes) {
      this.setState({ activeType: this.props.resourceTypes[0] });
    }
  };

  setActiveType = (activeType) => {
    this.setState({ activeType });
  };

  setActiveResource = (resource) => {
    this.setState(({ activeResource }) => {
      return { activeResource: resource === activeResource ? null : resource };
    });
  };

  render() {
    const { resources, memberUpdates, resourceTypes } = this.props;
    const { activeType } = this.state;
    const groupedResources = resources.reduce(
      (acc, resource) => {
        const { type } = resource.node.frontmatter;
        const targetAcc = acc[type];
        const { frontmatter } = resource.node;
        if (
          [
            'current repertoire',
            'old repertoire',
            'christmas songs',
            'choir+',
            'warmups',
            'beginners',
          ].includes(type)
        ) {
          let targetGroup = targetAcc[frontmatter.group];
          const targetParts = frontmatter.parts
            ? frontmatter.parts.split(', ')
            : null;
          if (targetGroup) {
            (targetParts || ['all']).forEach((part) => {
              targetGroup[part] = targetGroup[part]
                ? [...targetGroup[part], frontmatter.sourceLink]
                : [frontmatter.sourceLink];
            });
          } else {
            targetAcc[frontmatter.group] = {};
            targetGroup = targetAcc[frontmatter.group];
            (targetParts || ['all']).forEach((part) => {
              targetGroup[part] = targetGroup[part]
                ? [...targetGroup[part], frontmatter.sourceLink]
                : [frontmatter.sourceLink];
            });
          }
        } else {
          targetAcc.push(frontmatter);
        }
        return acc;
      },
      {
        'current repertoire': {},
        'old repertoire': {},
        'sheet music': {},
        'christmas songs': {},
        'choir+': {},
        beginners: {},
        warmups: {},
        'choir recordings': [],
        education: [],
        links: [],
        miscellaneous: [],
      }
    );
    return (
      <>
        <Navbar>
          <FlexCollection>
            {resourceTypes.map((type) => (
              <FlexButton
                isActiveType={type === this.state.activeType}
                onClick={() => this.setActiveType(type)}
                key={type}
              >
                {type
                  .split(' ')
                  .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
                  .join(' ')}
              </FlexButton>
            ))}
          </FlexCollection>
        </Navbar>
        {this.state.activeType === 'education' && (
          <Opening>
            Every now and again an article or video will appear that can be of
            great interest to the singer who wishes to learn. Below you find
            articles that I have found useful, videos and recordings that are
            informative and beneficial to improving your vocal expertise.
          </Opening>
        )}
        {this.state.activeType === 'warmups' && (
          <Opening>
            Warm ups are a necessary part of a healthy singing practice. Every
            singer should warm up physically and vocally for at least 10-15
            minutes before each session or performance. Below, you will find a
            selection of warm ups to download.
          </Opening>
        )}
        {this.props.teachTrackIntro && (
          <>
            <Opening>
              To assist with learning our songs and specifically individual
              harmony parts, teach tracks are a useful tool. These can be
              especially helpful if you don’t read music.
            </Opening>
            <Opening>
              Click on the title of the song you wish to learn and a selection
              of tracks will appear for you to download.
            </Opening>
          </>
        )}
        {this.state.activeType === 'member updates' ? (
          <MemberUpdateList memberUpdates={memberUpdates} />
        ) : [
            'current repertoire',
            'old repertoire',
            'christmas songs',
            'choir+',
            'warmups',
            'beginners',
          ].includes(activeType) ? (
          Object.entries(groupedResources[activeType])
            .sort(([group1], [group2]) => {
              if (group1 < group2) return -1;
              if (group2 < group1) return 1;
              return 0;
            })
            .reduce((groupAcc, [group, groupDetails]) => {
              groupAcc.push(
                <ResourceWrapper key={group}>
                  <ResourceTitle
                    clickable
                    onClick={() => {
                      this.setActiveResource(group);
                    }}
                  >
                    {group}
                  </ResourceTitle>
                  {this.state.activeResource === group && (
                    <ResourceLinkArea>
                      {Object.entries(groupDetails)
                        .sort(([part1], [part2]) => {
                          if (
                            partOrder.indexOf(part1) < partOrder.indexOf(part2)
                          ) {
                            return -1;
                          }
                          if (
                            partOrder.indexOf(part2) < partOrder.indexOf(part1)
                          ) {
                            return 1;
                          }
                          return 0;
                        })
                        .reduce((partAcc, [part, links]) => {
                          partAcc.push(
                            <ResourceLink key={part}>
                              <FlexButton>
                                <a href={links[0]} download>
                                  <ButtonText>{part}</ButtonText>
                                </a>
                              </FlexButton>
                            </ResourceLink>
                          );
                          return partAcc;
                        }, [])}
                    </ResourceLinkArea>
                  )}
                  <LineFade />
                </ResourceWrapper>
              );
              return groupAcc;
            }, [])
        ) : (
          groupedResources[activeType]
            .sort((item1, item2) => {
              if (item1 < item2) return -1;
              if (item2 < item1) return 1;
              return 0;
            })
            .reduce((groupAcc, item) => {
              groupAcc.push(
                <ResourceWrapper key={item.sourceLink}>
                  <ResourceLink>
                    <FlexButton>
                      <a href={item.sourceLink} download>
                        <ButtonText>{item.group}</ButtonText>
                      </a>
                    </FlexButton>
                  </ResourceLink>
                  <LineFade />
                </ResourceWrapper>
              );
              return groupAcc;
            }, [])
        )}
      </>
    );
  }
}

export default ResourceSearch;
