import React from "react";
import PropTypes from "prop-types";
import { ContentWrapper } from "./styled-components/structures";

export const HTMLContent = ({ content, className }) => (
  <ContentWrapper
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <ContentWrapper className={className}>{content}</ContentWrapper>
);

Content.defaultProps = {
  content: null,
  className: "",
};

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;
HTMLContent.defaultProps = Content.defaultProps;

export default Content;
